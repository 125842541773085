import Container from "@mui/material/Container";
import React, { useState } from "react";
import { Grid } from "tabler-react";
import { EntityTypes, UserRoles } from "../../../types/enums";
import { AttachmentViewer } from "./../../attachment-viewer";
import SecurityIcon from "@mui/icons-material/Security";
import "./incident-form.css";
import _ from "lodash";
import { IconButton, Stack } from "@mui/material";
import { Lock, LockOpenOutlined } from "@mui/icons-material";
import { roleMatch } from "actions/auth";
import { canViewInvestigation } from "services/incident-assignment-service";
import { useSelector } from "react-redux";

export function IncidentAttachments(props) {
  const account = useSelector((state) => state.account);
  const isInitiallyLocked = props.incident?.status?.toLowerCase() == "closed";
  const [isLocked, setIsLocked] = useState({
    investigationAttch: isInitiallyLocked,
    initialReportAttch: isInitiallyLocked,
  });

  const toggleLock = (name) => {
    const newState = { ...isLocked };
    const current = _.get(newState, name);
    _.set(newState, name, !current);
    setIsLocked(newState);
  };

  const resetIsLocked = () => {
    setIsLocked({
      investigationAttch: isInitiallyLocked,
      initialReportAttch: isInitiallyLocked,
    });
  };
  return (
    <Container className="mt-2" maxWidth="xl">
      {canViewInvestigation(props.incident, account.id) && (
        <>
          <Grid.Col md={12} width={12} className="pl-0 pr-0 mb-2">
            <AttachmentViewer
              title={
                <Stack direction="row" alignItems="center">
                  <span>Investigation Attachments</span>
                  {roleMatch([UserRoles.Admin]) && isInitiallyLocked && (
                    <IconButton
                      aria-label="Lock"
                      onClick={() => toggleLock("investigationAttch")}
                      title={isLocked.investigationAttch ? "Unlock" : "Lock"}
                    >
                      {isLocked.investigationAttch ? (
                        <Lock />
                      ) : (
                        <LockOpenOutlined color="success" />
                      )}
                    </IconButton>
                  )}
                  <SecurityIcon className="ml-3" />
                </Stack>
              }
              isExistingEntity={true}
              entityType={EntityTypes.IncidentInvestigation}
              entityId={props.incident.id}
              cardClass="mb-0"
              rowHeight={200}
              cols={6}
              isLocked={isLocked.investigationAttch}
              onUpload={() => resetIsLocked()}
            />
          </Grid.Col>
        </>
      )}

      <Grid.Col md={12} width={12} className="pl-0 pr-0">
        <AttachmentViewer
          title={
            <Stack direction="row" alignItems="center">
              <span>Initial Report Attachments</span>
              {roleMatch([UserRoles.Admin]) && isInitiallyLocked && (
                <IconButton
                  aria-label="Lock"
                  onClick={() => toggleLock("initialReportAttch")}
                  title={isLocked.initialReportAttch ? "Unlock" : "Lock"}
                >
                  {isLocked.initialReportAttch ? (
                    <Lock />
                  ) : (
                    <LockOpenOutlined color="success" />
                  )}
                </IconButton>
              )}
              <SecurityIcon className="ml-3" />
            </Stack>
          }
          isExistingEntity={true}
          entityType={EntityTypes.Incident}
          entityId={props.incident.id}
          cardClass="mb-0"
          rowHeight={200}
          cols={6}
          isLocked={isLocked.initialReportAttch}
          onUpload={() => resetIsLocked()}
        />
      </Grid.Col>
    </Container>
  );
}

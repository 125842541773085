import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import { EventList } from "./components/dashboards/events/EventList";
import { AssetSettings } from "./components/dashboards/settings/AssetSettings/AssetSettings";
import { TagSettings } from "./components/dashboards/settings/ComplianceSettings/tag-settings";
import { EventSettings } from "./components/dashboards/settings/EventSettings/EventSettings";
import { Settings } from "./components/dashboards/settings/Settings";
import { ContactSettings } from "./components/dashboards/settings/contacts/contact-settings";
import { MonitoringSettings } from "./components/dashboards/settings/monitoring/monitoring-settings";
import { OrganizationSettings } from "./components/dashboards/settings/organization-settings";

import { AuditLogList } from "./components/dashboards/audit-log/audit-log-list";
import { ContactList } from "./components/dashboards/contacts/contact-list";
import { AddMonitoringEvent } from "./components/dashboards/monitoring/add-monitoring-event";
import { MonitoringLimits } from "./components/dashboards/monitoring/monitoring-limits";
import { MonitoringList } from "./components/dashboards/monitoring/monitoring-list";

import { ReportsList } from "./components/dashboards/reports/reports-list";
import { PrivacyPolicy } from "./components/privacy";
import AccountContainer from "./containers/AccountContainer";
import LoginContainer from "./containers/LoginContainer";

import { About } from "./components/dashboards/about/about";
import { AssetList } from "./components/dashboards/assets/asset-list";
import { UserDashboard } from "./components/dashboards/dashboards/user-dashboard";
import { Imports } from "./components/dashboards/imports/imports";
import { KpiDetails } from "./components/dashboards/kpis/kpi-details";
import { KpiList } from "./components/dashboards/kpis/kpi-list";
import { DashboardMap } from "./components/dashboards/map/dashboard-map";

import PageNotFound from "./components/PageNotFound";
import { EventDetailsContainer } from "./components/dashboards/events/event-details-container";
import { NotificationSettings } from "./components/dashboards/settings/notifications/notification-settings";
import { ActionList } from "./components/dashboards/actions/action-list";
import { ForgotPassword } from "./components/login/forgot-password";
import { ResetForgotPassword } from "./components/login/reset-forgot-password";

import { OrganizationList } from "./components/admin/OrganizationList";
import { CommunityPortal } from "./components/community-portal/community-portal";
import { SubmissionDetails } from "./components/community-portal/submission-details";
import { SubmissionsList } from "./components/community-portal/submissions-list";
import { EventForm } from "./components/dashboards/events/event-form";
import { ActionSettings } from "./components/dashboards/settings/actions/actions-settings";
import { EnergyTrackingSettings } from "./components/dashboards/settings/energy/energy-tracking-settings";
import { IncidentSettings } from "./components/dashboards/settings/incidents/incident-settings";
import { ActionForm } from "./components/dashboards/actions/action-form";
import { AssetForm } from "./components/dashboards/assets/asset-form";
import { EnergyLogForm } from "./components/dashboards/energy/energy-log-form";
import { EnergyLogList } from "./components/dashboards/energy/energy-log-list";
import { ImportHistory } from "./components/dashboards/imports/import-history";
import { ImportSpecifications } from "./components/dashboards/imports/import-specifications";
import { IncidentContainer } from "./components/dashboards/incidents/incident-container";
import { IncidentsList } from "./components/dashboards/incidents/incidents-list";
import { InspectionForm } from "./components/dashboards/inspections/inspection-form";
import { InspectionList } from "./components/dashboards/inspections/inspection-list";
import { MonitoringLocationForm } from "./components/dashboards/monitoring/monitoring-location-form";
import { MonitoringVariance } from "./components/dashboards/monitoring/monitoring-variance";
import { MonitoringLocationUploadPhotos } from "./components/dashboards/time-series/monitoring-location-upload-photos";
import { RegisterAccount } from "./components/login/register-account";
import { UserRoles } from "./types/enums";

import { FormBuilder } from "./components/dashboards/settings/custom-fields/form-builder";
import { DeviceSettings } from "./components/dashboards/settings/device/device-settings";
import { InspectionSettings } from "./components/dashboards/settings/inspections/inspection-settings";
import { PermitSettings } from "./components/dashboards/settings/permits/permit-settings";
import { ContactForm } from "./components/dashboards/contacts/contact-form";

import { EquipmentList } from "./components/dashboards/equipment/equipment-list";
import { PermitForm } from "./components/dashboards/permits/permit-form";
import { PermitList } from "./components/dashboards/permits/permit-list";
import { DemoMicrosite } from "./components/microsites/demo";
import { PhotoImportHistoryReview } from "./components/dashboards/time-series/photo-import-history-review";
import { PhotoImportHistoryTraining } from "./components/dashboards/time-series/photo-import-history-training";
import { OrganizationPortalSettings } from "./components/dashboards/settings/organization-portal-settings";
import { WylooMicrositeHome } from "./components/microsites/wyloo/wyloo-microsite-home";
import { WylooMicrositeWater } from "./components/microsites/wyloo/wyloo-microsite-water";
import { WylooLocationDetails } from "./components/microsites/wyloo/wyloo-location-details";
import { WylooMicrositeWildlife } from "components/microsites/wyloo/wyloo-microsite-wildlife";
import { WylooMicrositeAir } from "components/microsites/wyloo/wyloo-microsite-air";
import PortalRoute from "components/portal-route";

export default function AppRoute(props) {
  return (
    <Switch>
      <Route path="/" exact component={LoginContainer} />
      <Route path="/privacy" exact component={PrivacyPolicy} />
      <Route path="/forgot-password" exact component={ForgotPassword} />
      <Route path="/community-portal" exact component={CommunityPortal} />
      <Route path="/investor" exact component={DemoMicrosite} />
      <Route path="/wyloo" exact component={WylooMicrositeHome} />
      <Route path="/wyloo/water" exact component={WylooMicrositeWater} />
      <Route path="/wyloo/air" exact component={WylooMicrositeAir} />
      <Route path="/wyloo/wildlife" exact component={WylooMicrositeWildlife} />
      <PortalRoute
        roles={[UserRoles.WylooUser, UserRoles.PortalUser]}
        path="/wyloo/monitoring-locations/:id"
        exact
        component={WylooLocationDetails}
      />
      <Route
        path="/reset-forgot-password/:guid"
        exact
        component={ResetForgotPassword}
      />
      <Route path="/register/:guid" exact component={RegisterAccount} />
      <PrivateRoute path="/account" exact component={AccountContainer} />
      <Route exact path="/authorize" component={LoginContainer} />
      <PrivateRoute
        path="/dashboard"
        exact
        component={(props) => <UserDashboard {...props} />}
      />
      <PrivateRoute
        path="/dashboard/home"
        exact
        component={(props) => <Redirect to="/dashboard" {...props} />}
      />
      <PrivateRoute
        path="/map"
        exact
        component={(props) => <DashboardMap {...props} />}
      />
      <PrivateRoute
        path="/assets"
        roles={[UserRoles.Admin]}
        exact
        component={(props) => <AssetList {...props} />}
      />{" "}
      <PrivateRoute
        path="/assets/:assetId"
        roles={[UserRoles.Admin]}
        exact
        component={(props) => <AssetForm {...props} />}
      />
      <PrivateRoute
        path="/add-asset"
        roles={[UserRoles.Admin]}
        exact
        component={(props) => <AssetForm {...props} />}
      />
      <PrivateRoute
        path="/dashboard/assets"
        exact
        roles={[UserRoles.Admin]}
        component={(props) => <Redirect to="/assets" {...props} />}
      />
      <PrivateRoute
        path="/reports"
        exact
        component={(props) => <ReportsList {...props} />}
      />
      <PrivateRoute
        path="/events"
        exact
        roles={[UserRoles.EventsUser]}
        component={(props) => <EventList {...props} />}
      />
      <PrivateRoute
        path="/add-event"
        exact
        roles={[UserRoles.EventsUser]}
        component={(props) => <EventForm entityId={0} {...props} />}
      />
      <PrivateRoute
        path="/events/:eventId"
        exact
        roles={[UserRoles.EventsUser]}
        component={(props) => <EventDetailsContainer {...props} />}
      />
      <PrivateRoute
        path="/photo-import-history/:photoImportHistoryId"
        exact
        roles={[UserRoles.TimeSeriesUser]}
        component={(props) => <PhotoImportHistoryReview {...props} />}
      />
      <PrivateRoute
        path="/photo-import-history/:photoImportHistoryId/training"
        exact
        roles={[UserRoles.TimeSeriesUser]}
        component={(props) => <PhotoImportHistoryTraining {...props} />}
      />
      <PrivateRoute
        path="/events/:eventId/add-action"
        exact
        roles={[UserRoles.EventsUser]}
        component={(props) => <ActionForm {...props} />}
      />
      <PrivateRoute
        path="/events/:eventId/actions/:id"
        exact
        roles={[UserRoles.EventsUser]}
        component={(props) => <ActionForm {...props} />}
      />
      <PrivateRoute
        path="/dashboard/events"
        exact
        component={(props) => <Redirect to="/events" {...props} />}
      />
      <PrivateRoute
        path="/incidents"
        exact
        roles={[UserRoles.IncidentsUser]}
        component={(props) => <IncidentsList {...props} />}
      />
      <PrivateRoute
        path="/actions"
        exact
        component={(props) => <ActionList {...props} />}
      />
      <PrivateRoute
        path="/add-action"
        exact
        component={(props) => <ActionForm {...props} />}
      />
      <PrivateRoute
        path="/actions/:id"
        exact
        component={(props) => <ActionForm {...props} />}
      />
      <PrivateRoute
        path="/submit-incident"
        exact
        roles={[UserRoles.IncidentsUser]}
        component={(props) => <IncidentContainer {...props} />}
      />
      <PrivateRoute
        path="/incidents/:id"
        exact
        roles={[UserRoles.IncidentsUser]}
        component={(props) => <IncidentContainer {...props} />}
      />
      <PrivateRoute
        path="/incidents/:incidentId/add-action"
        exact
        roles={[UserRoles.IncidentsUser]}
        component={(props) => <ActionForm {...props} />}
      />
      <PrivateRoute
        path="/incidents/:incidentId/actions/:id"
        exact
        roles={[UserRoles.IncidentsUser]}
        component={(props) => <ActionForm {...props} />}
      />
      <PrivateRoute
        path="/add-monitoring-event"
        exact
        roles={[UserRoles.MonitoringUser]}
        component={(props) => <AddMonitoringEvent {...props} />}
      />
      <PrivateRoute
        path="/monitoring-locations/:monLocId/limits"
        exact
        roles={[UserRoles.MonitoringUser]}
        component={(props) => <MonitoringLimits {...props} />}
      />
      <PrivateRoute
        path="/monitoring-locations/:monLocId/variance"
        exact
        roles={[UserRoles.MonitoringUser]}
        component={(props) => <MonitoringVariance {...props} />}
      />
      <PrivateRoute
        path="/monitoring/:eventId"
        exact
        roles={[UserRoles.MonitoringUser]}
        component={(props) => <AddMonitoringEvent {...props} />}
      />
      <PrivateRoute
        path="/monitoring"
        exact
        roles={[UserRoles.MonitoringUser]}
        component={(props) => <MonitoringList {...props} />}
      />
      <PrivateRoute
        path="/monitoring-locations/:monLocId"
        exact
        roles={[UserRoles.MonitoringUser]}
        component={(props) => <MonitoringLocationForm {...props} />}
      />
      <PrivateRoute
        path="/add-monitoring-location"
        exact
        roles={[UserRoles.MonitoringUser]}
        component={(props) => <MonitoringLocationForm {...props} />}
      />
      <PrivateRoute
        path="/inspections"
        roles={[UserRoles.InspectionsUser]}
        exact
        component={(props) => <InspectionList {...props} />}
      />
      <PrivateRoute
        path="/equipment"
        roles={[UserRoles.InspectionsUser]}
        exact
        component={(props) => <EquipmentList {...props} />}
      />
      <PrivateRoute
        path="/new-inspection"
        roles={[UserRoles.InspectionsUser]}
        exact
        component={(props) => <InspectionForm {...props} />}
      />
      <PrivateRoute
        path="/inspections/:inspectionId"
        exact
        roles={[UserRoles.InspectionsUser]}
        component={(props) => <InspectionForm {...props} />}
      />
      <PrivateRoute
        path="/energy-logs"
        exact
        roles={[UserRoles.EnergyUser]}
        component={(props) => <EnergyLogList {...props} />}
      />
      <PrivateRoute
        path="/energy-logs/:id"
        exact
        roles={[UserRoles.EnergyUser]}
        component={(props) => <EnergyLogForm {...props} />}
      />
      <PrivateRoute
        path="/add-energy-log"
        exact
        roles={[UserRoles.EnergyUser]}
        component={(props) => <EnergyLogForm {...props} />}
      />
      <PrivateRoute
        path="/permits"
        exact
        component={(props) => <PermitList {...props} />}
      />
      <PrivateRoute
        path="/permits/:id"
        exact
        component={(props) => <PermitForm {...props} />}
      />
      <PrivateRoute
        path="/add-permit"
        exact
        component={(props) => <PermitForm {...props} />}
      />
      <PrivateRoute
        path="/monitoring-location-upload-photos"
        exact
        roles={[UserRoles.TimeSeriesUser]}
        component={(props) => <MonitoringLocationUploadPhotos {...props} />}
      />
      <PrivateRoute
        path="/settings"
        component={(props) => <Settings {...props} />}
        exact
      />
      <PrivateRoute
        path="/settings/incidents"
        component={(props) => <IncidentSettings {...props} />}
        exact
        roles={[UserRoles.IncidentsUser, UserRoles.Admin]}
      />
      <PrivateRoute
        path="/settings/portal"
        component={(props) => <OrganizationPortalSettings {...props} />}
        exact
        roles={[UserRoles.Admin]}
      />
      <PrivateRoute
        path="/settings/events"
        component={(props) => <EventSettings {...props} />}
        exact
        roles={[UserRoles.EventsUser, UserRoles.Admin]}
      />
      <PrivateRoute
        path="/settings/inspections"
        component={(props) => <InspectionSettings {...props} />}
        exact
        roles={[UserRoles.InternalUser]}
      />
      <PrivateRoute
        path="/settings/incidents/form-builder"
        component={(props) => <FormBuilder {...props} />}
        exact
        roles={[UserRoles.IncidentsUser, UserRoles.InternalUser]}
      />{" "}
      <PrivateRoute
        path="/settings/incidents/form-builder/:id"
        component={(props) => <FormBuilder {...props} />}
        exact
        roles={[UserRoles.IncidentsUser, UserRoles.InternalUser]}
      />
      <PrivateRoute
        path="/settings/inspections/form-builder"
        component={(props) => <FormBuilder {...props} />}
        exact
        roles={[UserRoles.InspectionsUser, UserRoles.InternalUser]}
      />
      <PrivateRoute
        path="/settings/inspections/form-builder/:id"
        component={(props) => <FormBuilder {...props} />}
        exact
        roles={[UserRoles.InspectionsUser, UserRoles.InternalUser]}
      />
      <PrivateRoute
        path="/settings/assets"
        roles={[UserRoles.Admin]}
        component={(props) => <AssetSettings {...props} />}
        exact
      />
      <PrivateRoute
        path="/settings/tags"
        component={(props) => <TagSettings {...props} />}
        exact
      />
      <PrivateRoute
        path="/settings/contacts"
        component={(props) => <ContactSettings {...props} />}
        exact
        roles={[UserRoles.EventsUser, UserRoles.Admin]}
      />
      <PrivateRoute
        path="/settings/monitoring"
        component={(props) => <MonitoringSettings {...props} />}
        exact
        roles={[UserRoles.MonitoringUser, UserRoles.Admin]}
      />
      <PrivateRoute
        path="/settings/actions"
        component={(props) => <ActionSettings {...props} />}
        exact
      />
      <PrivateRoute
        path="/settings/energy-tracking"
        component={(props) => <EnergyTrackingSettings {...props} />}
        exact
        roles={[UserRoles.EnergyUser, UserRoles.Admin]}
      />
      <PrivateRoute
        path="/settings/permits"
        component={(props) => <PermitSettings {...props} />}
        exact
        roles={[UserRoles.Admin]}
      />
      <PrivateRoute
        path="/contacts"
        exact
        component={(props) => <ContactList {...props} />}
      />
      <PrivateRoute
        path="/add-contact"
        exact
        component={(props) => <ContactForm {...props} />}
      />
      <PrivateRoute
        path="/contacts/:contactId"
        exact
        component={(props) => <ContactForm {...props} />}
      />
      <PrivateRoute
        path="/portal-submissions"
        exact
        component={(props) => <SubmissionsList {...props} />}
      />
      <PrivateRoute
        path="/portal-submissions/:id"
        exact
        component={(props) => <SubmissionDetails {...props} />}
      />
      <PrivateRoute
        roles={[UserRoles.Admin]}
        path="/settings/organization"
        component={OrganizationSettings}
      />
      <PrivateRoute
        path="/settings/notifications"
        component={NotificationSettings}
      />
      <PrivateRoute
        roles={[UserRoles.Admin]}
        path="/audit-log"
        component={AuditLogList}
      />
      <PrivateRoute path="/settings/device" component={DeviceSettings} />
      <PrivateRoute
        roles={[UserRoles.SuperAdmin]}
        path={`/admin`}
        component={(newProps) => <OrganizationList {...newProps} />}
      />
      <PrivateRoute exact path="/kpis" component={KpiList} />
      <PrivateRoute exact path="/kpis/:id" component={KpiDetails} />
      <PrivateRoute path="/about" component={About} />
      <PrivateRoute
        roles={[UserRoles.Admin]}
        path="/imports"
        component={Imports}
      />
      <PrivateRoute
        roles={[UserRoles.Admin]}
        path="/import-history"
        component={ImportHistory}
      />
      <PrivateRoute
        roles={[UserRoles.Admin]}
        path="/import-configurations"
        component={ImportSpecifications}
      />
      <Route component={PageNotFound} />
    </Switch>
  );
}

import React from "react";
import { Card } from "tabler-react";
import "./donut-chart-widget.css";
import { WidgetHeaderButton } from "./widget-header-button";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import { dateUtil } from "../../../../services/date-util";
export function DashboardWidgetHeader(props) {
  return (
    <Card.Header>
      <Card.Title>
        {props.title ?? props.widget.title}
        {props.lastCalculatedDate && (
          <>
            <br />
            <Typography variant="caption">
              Last calculated:&nbsp;
              {dateUtil.convertDateTimeToLocal(props.lastCalculatedDate)}
            </Typography>
          </>
        )}
      </Card.Title>

      {props.widget && (
        <WidgetHeaderButton
          widget={props.widget}
          handleSortChange={props.handleSortChange}
          handleDelete={props.handleDelete}
          upDisabled={props.upDisabled}
          downDisabled={props.downDisabled}
          handleRefresh={props.handleRefresh}
          handleExportWidget={props.handleExportWidget}
        />
      )}
      {props.kpiId > 0 && (
        <IconButton
          aria-label="refresh"
          className="ml-auto "
          onClick={props.handleRefresh}
        >
          <RefreshIcon />
        </IconButton>
      )}
    </Card.Header>
  );
}

import React, { useEffect, useMemo, useState } from "react";
import { Card, Form, Grid, Icon } from "tabler-react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import { DataGridPro } from "@mui/x-data-grid-pro";
import alertify from "alertifyjs";
import * as jsonpatch from "fast-json-patch";
import _ from "lodash";
import {
  hasAssetPermission,
  hasLookupPermissions,
} from "../../../actions/auth";
import { validationService } from "../../../services";
import { getLookups } from "./../../../services/lookup";
import { patchUser } from "./../../../services/users";
import { LynxDialog } from "./../../lynx-dialog";
import { getAssetLookups } from "../../../services/assets";
import { LookupTypes } from "../../../types/enums";
import { useDispatch, useSelector } from "react-redux";
import {
  getDepartmentByUserId,
  getDepartments,
} from "services/department-service";
import { MultiSelect } from "components/form-controls/multi-select";
import { MenuItem, ListItemText } from "@mui/material";
import { useGetRolesQuery } from "services/rtkApi/endpoints/roles";
import SingleSelect from "components/form-controls/single-select";
import { loadAccount } from "actions/account";

export function UserModal(props) {
  const [formState, setFormState] = useState({});
  const [departmentUsers, setDepartmentUsers] = useState();
  const [initialFormState, setInitialFormState] = useState({});
  const [eventCategories, setEventCategories] = useState([]);
  const [eventPermissionsData, setEventPermissionData] = useState([]);
  const [monPermissionData, setMonPermissionData] = useState([]);
  const [showSaveDialog, setShowSaveDialog] = useState(false);
  const [monEventTypes, setMonEventTypes] = useState([]);
  const [assetPermissionData, setAssetPermissionData] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [assets, setAssets] = useState([]);
  var organization = useSelector((state) => state.organization);
  const [eventRole, setEventRole] = useState();
  const rolesQuery = useGetRolesQuery();

  const dispatch = useDispatch();

  useEffect(() => {
    if (props.entity) {
      setFormStateFromProps(props.entity);
      getLookups(LookupTypes.EventCategory, true).then((res) => {
        setEventCategories(res.data);
      });
      getLookups(LookupTypes.MonitoringEventType, true).then((res) => {
        setMonEventTypes(res.data);
      });
      getAssetLookups(true).then((res) => {
        setAssets(res.data);
      });
      getDepartments().then((res) => {
        setDepartments(
          res.data.map((m) => ({
            departmentId: m.id,
            name: m.name,
            isDepartmentHead: false,
            userId: props.entity.id,
          }))
        );
      });
    }
  }, [props.entity]);

  useEffect(() => {
    if (rolesQuery.data) {
      const currentRole = rolesQuery.data.filter((role) => {
        return props.entity.userRoles.some((f) => f == role);
      });

      handleChangeEventRole(currentRole ? currentRole[0] : "");
    }
  }, [rolesQuery.data]);

  useEffect(() => {
    if (departments.length > 0) {
      //the user's department may have changed in same place.
      //call api to get latest value of userdepartment
      getDepartmentByUserId(props.entity.id).then((res) => {
        const deptUsers = res.data.map((m) => {
          const dept = departments.find(
            (f) => f.departmentId == m.departmentId
          );
          return {
            departmentId: dept.departmentId,
            name: dept.name,
            isDepartmentHead: m.isDepartmentHead,
            userId: props.entity.id,
          };
        });
        setDepartmentUsers(deptUsers);
      });
    }
  }, [departments]);

  useEffect(() => {
    if (!_.isEmpty(eventCategories) && !_.isEmpty(props.entity)) {
      let data = [];
      eventCategories.forEach((cat) => {
        let catData = {
          id: cat.id,
          code: cat.code,
          canApprove: hasLookupPermissions(
            cat.id,
            "canApprove",
            props.entity.userLookups
          ),
          canVerify: hasLookupPermissions(
            cat.id,
            "canVerify",
            props.entity.userLookups
          ),
          canPerformInitialReview: hasLookupPermissions(
            cat.id,
            "canPerformInitialReview",
            props.entity.userLookups
          ),
          canAccess: hasLookupPermissions(
            cat.id,
            null,
            props.entity.userLookups
          ),
          canPerformFinalReview: hasLookupPermissions(
            cat.id,
            "canPerformFinalReview",
            props.entity.userLookups
          ),
        };
        data.push(catData);
      });
      setEventPermissionData(data);
    }
  }, [eventCategories, props.entity]);

  useEffect(() => {
    if (!_.isEmpty(monEventTypes) && !_.isEmpty(props.entity)) {
      let data = [];
      monEventTypes.forEach((cat) => {
        let catData = {
          id: cat.id,
          code: cat.code,
          canAccess: hasLookupPermissions(
            cat.id,
            null,
            props.entity.userLookups
          ),
        };
        data.push(catData);
      });
      setMonPermissionData(data);
    }
  }, [monEventTypes, props.entity]);

  useEffect(() => {
    if (!_.isEmpty(assets) && !_.isEmpty(props.entity)) {
      let data = [];
      assets.forEach((asset) => {
        let catData = {
          id: asset.id,
          name: asset.name,
          canAccess: hasAssetPermission(asset.id, props.entity.userAssets),
        };
        data.push(catData);
      });
      setAssetPermissionData(data);
    }
  }, [assets, props.entity]);

  const handleDepartmentChange = (event) => {
    const {
      target: { value },
    } = event;

    let duplicateRemoved = [];

    value.forEach((item) => {
      if (
        duplicateRemoved.findIndex(
          (o) => o.departmentId === item.departmentId
        ) == -1
      ) {
        duplicateRemoved.push(item);
      } else {
        duplicateRemoved = duplicateRemoved.filter(
          (x) => x.departmentId !== item.departmentId
        );
      }
    });

    setDepartmentUsers(duplicateRemoved);
  };

  const handleRightCheckboxChange = (e, metadata) => {
    const newDeptUsers = [...departmentUsers];
    const index = newDeptUsers.findIndex(
      (f) => f.departmentId == metadata.departmentId
    );

    if (index != -1) {
      newDeptUsers[index].isDepartmentHead = e.target.checked;

      setDepartmentUsers(newDeptUsers);
    }
  };

  const handleInputChange = (e) => {
    let newState = { ...formState };
    const name = e.target.name;
    const value =
      name === "isActive" ||
      name === "isEventsUser" ||
      name === "isMonitoringUser" ||
      name === "isIncidentsUser" ||
      name === "isEnergyUser" ||
      name === "isInspectionsUser" ||
      name === "isEnergyReviewer" ||
      name === "isTimeSeriesUser" ||
      name === "hasEventConfidentialAccess"
        ? e.target.checked
        : e.target.value;
    _.set(newState, name, value);
    setFormState(newState);
  };

  const setFormStateFromProps = (user) => {
    let newState = {
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      isAdmin: user.isAdmin,
      isActive: user.isActive,
      isEventsUser: user.isEventsUser,
      isIncidentsUser: user.isIncidentsUser,
      isMonitoringUser: user.isMonitoringUser,
      isEnergyUser: user.isEnergyUser,
      isEnergyReviewer: user.isEnergyReviewer,
      registrationCompletedDateTimeUtc: user.registrationCompletedDateTimeUtc,
      isInspectionsUser: user.isInspectionsUser,
      isTimeSeriesUser: user.isTimeSeriesUser,
      hasEventConfidentialAccess: user.hasEventConfidentialAccess,
    };

    setFormState(newState);
    setInitialFormState(newState);
  };

  const saveForm = () => {
    //validate here
    if (!validateDataForSave()) {
      return;
    }
    let formToSave = validationService.unsetErrors(
      formState,
      "firstNameError",
      "lastNameError"
    );

    //unset errors

    var diff = jsonpatch.compare(initialFormState, formToSave);
    var dto = {
      userPatch: diff,
      eventPermissions: eventPermissionsData,
      assetPermissions: assetPermissionData
        .filter((x) => x.canAccess)
        .map((a) => a.id),
      monEventPermissions: monPermissionData,
      departmentUsers,
      eventRole: eventRole || "",
    };

    patchUser(props.entity.id, dto)
      .then((res) => {
        setShowSaveDialog(true);
        dispatch(loadAccount());
      })
      .then(() => {
        //after saving user, this should refresh the department section.
        props.onAfterSave && props.onAfterSave();
      });
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "firstName",
      "firstNameError",
      "First name"
    );
    validationService.validateRequiredField(
      newState,
      "lastName",
      "lastNameError",
      "Last name"
    );

    isFormValid = !validationService.hasError(
      newState,
      "firstNameError",
      "lastNameError"
    );

    if (!isFormValid) {
      setFormState(newState);
      alertify.error("Form is not valid for saving.");
    }
    return isFormValid;
  };

  const handleEventPermissionsChange = (e, cat) => {
    const { name, checked } = e.target;

    let existingPermissions = [...eventPermissionsData];
    var permissionIndex = existingPermissions.findIndex((x) => x.id == cat.id);
    _.set(existingPermissions[permissionIndex], name, checked);
    setEventPermissionData(existingPermissions);
  };

  const handleMonPermissionsChange = (e, cat) => {
    const { name, checked } = e.target;

    let existingPermissions = [...monPermissionData];
    var permissionIndex = existingPermissions.findIndex((x) => x.id == cat.id);
    _.set(existingPermissions[permissionIndex], name, checked);
    setEventPermissionData(existingPermissions);
  };

  const handleAssetPermissionsChanged = (e, asset) => {
    const { name, checked } = e.target;

    let existingPermissions = [...assetPermissionData];
    var permissionIndex = existingPermissions.findIndex(
      (x) => x.id == asset.id
    );
    _.set(existingPermissions[permissionIndex], name, checked);
    setAssetPermissionData(existingPermissions);
  };

  const assetPermissionsColumns = [
    {
      field: "name",
      headerName: "Asset",
      width: 150,
      type: "string",
    },
    {
      field: "canAccess",
      headerName: "Can access",
      width: 200,
      type: "",
      renderCell: (params) => (
        <div>
          <Checkbox
            checked={params.value}
            name="canAccess"
            onChange={(e) => handleAssetPermissionsChanged(e, params.row)}
          />
        </div>
      ),
    },
  ];

  const monPermissionColumns = [
    {
      field: "code",
      headerName: "Event Type",
      width: 150,
      type: "string",
    },
    {
      field: "canAccess",
      headerName: "Can access",
      width: 200,
      type: "",
      renderCell: (params) => (
        <div>
          <Checkbox
            checked={params.value}
            name="canAccess"
            onChange={(e) => handleMonPermissionsChange(e, params.row)}
          />
        </div>
      ),
    },
  ];

  const eventPermissionColumns = [
    {
      field: "code",
      headerName: "Event Category",
      width: 150,
      type: "string",
    },
    {
      field: "canAccess",
      headerName: "Can view",
      width: 200,
      type: "boolean",
      renderCell: (params) => (
        <div>
          <Checkbox
            checked={params.value}
            name="canAccess"
            onChange={(e) => handleEventPermissionsChange(e, params.row)}
          />
        </div>
      ),
    },

    {
      field: "canApprove",
      headerName: "Can approve actions",
      width: 200,
      type: "boolean",
      renderCell: (params) => (
        <div>
          {params.row.canAccess && (
            <Checkbox
              name="canApprove"
              checked={params.value}
              onChange={(e) => handleEventPermissionsChange(e, params.row)}
            />
          )}
        </div>
      ),
    },
    {
      field: "canVerify",
      headerName: "Can verify actions",
      width: 200,
      type: "boolean",
      renderCell: (params) => (
        <div>
          {params.row.canAccess && (
            <Checkbox
              name="canVerify"
              checked={params.value}
              onChange={(e) => handleEventPermissionsChange(e, params.row)}
            />
          )}
        </div>
      ),
    },
    {
      field: "canPerformFinalReview",
      headerName: "Can perform final review",
      width: 300,
      type: "boolean",
      renderCell: (params) => (
        <div>
          {params.row.canAccess && (
            <Checkbox
              name="canPerformFinalReview"
              checked={params.value}
              onChange={(e) => handleEventPermissionsChange(e, params.row)}
            />
          )}
        </div>
      ),
    },
  ];

  const roles = useMemo(() => {
    if (rolesQuery.data) {
      return rolesQuery.data.map((m, i) => ({
        id: m,
        role: m.replace("Events", ""),
      }));
    }
  }, [rolesQuery.data]);

  const handleChangeEventRole = (e) => {
    setEventRole(e?.target?.value || e);
  };

  return (
    <Form className="card dsmodal-main lynx-modal-80">
      <Card.Body>
        <Card.Title>
          Edit User
          <Icon
            name="x"
            onClick={() => props.handleModalClose(false)}
            className="float-right pointer"
          ></Icon>
        </Card.Title>
        <Grid.Row>
          <>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Email" isRequired>
                <Form.Input
                  disabled
                  name="email"
                  onChange={handleInputChange}
                  value={formState.email}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="First name" isRequired>
                <Form.Input
                  name="firstName"
                  error={formState.firstNameError}
                  onChange={handleInputChange}
                  value={formState.firstName}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Last name" isRequired>
                <Form.Input
                  name="lastName"
                  error={formState.lastNameError}
                  onChange={handleInputChange}
                  value={formState.lastName}
                ></Form.Input>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group isRequired label="Role">
                <Form.Select
                  name="isAdmin"
                  value={formState.isAdmin}
                  onChange={handleInputChange}
                >
                  <option value={false}>Regular user</option>
                  <option value={true}>Admin user</option>
                </Form.Select>
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Department">
                <MultiSelect
                  name="departmentUsers"
                  onChange={handleDepartmentChange}
                  value={departmentUsers || []}
                  dropdownValues={departments || []}
                  label="name"
                  keyProperty={"departmentId"}
                  hasRightCheckbox
                  header={
                    <MenuItem disabled sx={{ opacity: "1 !important" }}>
                      <ListItemText sx={{ px: 1 }}>Users</ListItemText>
                      <ListItemText sx={{ textAlign: "right" }}>
                        Department Head
                      </ListItemText>
                    </MenuItem>
                  }
                  onChangeRightCheckbox={handleRightCheckboxChange}
                />
              </Form.Group>
            </Grid.Col>
            {!formState.registrationCompletedDateTimeUtc && (
              <Grid.Col>
                <div className="text-red mb-2">
                  User is pending registration
                </div>
              </Grid.Col>
            )}
            <Grid.Col md={12} width={12}>
              <Form.Group>
                <Form.Checkbox
                  label="Active"
                  name="isActive"
                  onChange={handleInputChange}
                  checked={formState.isActive}
                />
              </Form.Group>
            </Grid.Col>
            <Grid.Col md={12} width={12}>
              <Form.Group label="Asset Permissions">
                <Box sx={{ height: 400, width: "100%" }}>
                  <DataGridPro
                    rows={assetPermissionData}
                    columns={assetPermissionsColumns}
                    disableSelectionOnClick
                  ></DataGridPro>
                </Box>
              </Form.Group>
            </Grid.Col>
            {organization.energyLogsLicenseCount > 0 && (
              <>
                <Grid.Col md={12} width={12}>
                  <Form.Group>
                    <Form.Checkbox
                      label="Has Access To Energy Module?"
                      name="isEnergyUser"
                      onChange={handleInputChange}
                      checked={formState.isEnergyUser}
                    />
                  </Form.Group>
                </Grid.Col>
                {formState.isEnergyUser && (
                  <Grid.Col md={12} width={12}>
                    <Form.Group className="ml-1">
                      <Form.Checkbox
                        label="Can Perform Final Review of Energy Logs?"
                        name="isEnergyReviewer"
                        onChange={handleInputChange}
                        checked={formState.isEnergyReviewer}
                      />
                    </Form.Group>
                  </Grid.Col>
                )}
              </>
            )}
            {organization.eventsLicenseCount > 0 && (
              <Grid.Col md={12} width={12}>
                <Form.Group>
                  <Form.Checkbox
                    label="Has Access To Events Module?"
                    name="isEventsUser"
                    onChange={handleInputChange}
                    checked={formState.isEventsUser}
                  />
                </Form.Group>
              </Grid.Col>
            )}
            {organization.eventsLicenseCount > 0 && formState.isEventsUser && (
              <Grid.Col md={12} width={12}>
                <Form.Group label="Event Permissions">
                  <Box sx={{ height: 400, width: "100%" }}>
                    <DataGridPro
                      rows={eventPermissionsData}
                      columns={eventPermissionColumns}
                      disableSelectionOnClick
                    ></DataGridPro>
                  </Box>
                </Form.Group>
              </Grid.Col>
            )}

            {organization.featureFlags.showInDevelopmentFeatures && (
              <Grid.Col md={12} width={12}>
                <Form.Group label="Event Permissions">
                  <Card>
                    <Card.Body className="p-3">
                      <Form.Group>
                        <Form.Checkbox
                          label="Confidential Access?"
                          name="hasEventConfidentialAccess"
                          onChange={(e) => {
                            handleInputChange(e);
                          }}
                          checked={formState.hasEventConfidentialAccess}
                        />
                      </Form.Group>
                      <Form.Group label="Event Permission User Role">
                        <SingleSelect
                          disabled={rolesQuery.isFetching}
                          onChange={handleChangeEventRole}
                          dropdownValues={roles || []}
                          label="role"
                          id="id"
                          name="eventRole"
                          value={eventRole || ""}
                        />
                      </Form.Group>
                    </Card.Body>
                  </Card>
                </Form.Group>
              </Grid.Col>
            )}

            {organization.incidentsLicenseCount > 0 && (
              <Grid.Col md={12} width={12}>
                <Form.Group>
                  <Form.Checkbox
                    label="Has Access To Incidents Module?"
                    name="isIncidentsUser"
                    onChange={handleInputChange}
                    checked={formState.isIncidentsUser}
                  />
                </Form.Group>
              </Grid.Col>
            )}
            {organization.inspectionsLicenseCount > 0 && (
              <Grid.Col md={12} width={12}>
                <Form.Group>
                  <Form.Checkbox
                    label="Has Access To Inspections Module?"
                    name="isInspectionsUser"
                    onChange={handleInputChange}
                    checked={formState.isInspectionsUser}
                  />
                </Form.Group>
              </Grid.Col>
            )}

            {organization.monitoringLicenseCount > 0 && (
              <Grid.Col md={12} width={12}>
                <Form.Group>
                  <Form.Checkbox
                    label="Has Access To Monitoring Module?"
                    name="isMonitoringUser"
                    onChange={handleInputChange}
                    checked={formState.isMonitoringUser}
                  />
                </Form.Group>
              </Grid.Col>
            )}
            {organization.monitoringLicenseCount > 0 &&
              formState.isMonitoringUser && (
                <Grid.Col md={12} width={12}>
                  <Form.Group label="Monitoring Permissions">
                    <Box sx={{ height: 400, width: "100%" }}>
                      <DataGridPro
                        rows={monPermissionData}
                        columns={monPermissionColumns}
                        disableSelectionOnClick
                      ></DataGridPro>
                    </Box>
                  </Form.Group>
                </Grid.Col>
              )}
            {organization.timeSeriesLicenseCount > 0 && (
              <Grid.Col md={12} width={12}>
                <Form.Group>
                  <Form.Checkbox
                    label="Has Access To Time Series Module?"
                    name="isTimeSeriesUser"
                    onChange={handleInputChange}
                    checked={formState.isTimeSeriesUser}
                  />
                </Form.Group>
              </Grid.Col>
            )}
          </>
        </Grid.Row>
      </Card.Body>
      <Card.Footer>
        <div>
          <Button
            variant="contained"
            className="float-right"
            onClick={saveForm}
          >
            Save user
          </Button>
        </div>
      </Card.Footer>
      {showSaveDialog && (
        <LynxDialog
          open={showSaveDialog}
          handleConfirm={() => props.handleModalClose(true)}
          title={`User Updated`}
          description={`The user must log out and log back in for any changes to take affect.`}
        />
      )}
    </Form>
  );
}

import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { Card, Form, Grid, Icon } from "tabler-react";
import alertify from "alertifyjs";
import _ from "lodash";
import { validationService } from "./../../../services/validation";
import { LynxDialog } from "../../lynx-dialog";

import {
  createEquipment,
  updateEquipment,
  deleteEquipment,
} from "./../../../services/equipment-service";
import { getAssetLookups } from "../../../services/assets";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";

export function EquipmentModal(props) {
  const [formState, setFormState] = useState({
    name: "",
    assetId: "",
    assetIdError: "",
    nameError: "",
    description: "",
  });
  const [assets, setAssets] = useState([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  useEffect(() => {
    if (!_.isEmpty(props.entity)) {
      setFormState({
        name: props.entity.name,
        assetId: props.entity.assetId,
        assetIdError: "",
        nameError: "",
        description: props.entity.description,
      });
    }

    getAssetLookups().then((res) => {
      setAssets(res.data);
    });

    if (props.defaultAssetId) {
      setFormState({ ...formState, assetId: props.defaultAssetId });
    }
  }, [props.entity]);
  const handleInputChange = (e) => {
    let newState = { ...formState };
    const name = e.target.name;
    const value = e.target.value;

    _.set(newState, name, value);
    setFormState(newState);
  };
  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;
    validationService.validateRequiredField(
      newState,
      "name",
      "nameError",
      "Equipment Name"
    );
    validationService.validateRequiredField(
      newState,
      "assetId",
      "assetIdError",
      "Asset"
    );

    isFormValid = !validationService.hasError(
      newState,
      "nameError",
      "assetIdError"
    );

    if (!isFormValid) {
      setFormState(newState);
      alertify.error("Form is not valid for saving.");
    }
    return isFormValid;
  };
  const saveForm = () => {
    //validate here
    if (!validateDataForSave()) {
      return;
    }
    let formToSave = validationService.unsetErrors(formState, "nameError");

    if (!_.isEmpty(props.entity)) {
      updateEquipment(props.entity.id, formToSave)
        .then((res) => {
          alertify.success("Equipment Updated.");
          props.handleModalClose(true);
        })
        .catch((err) => {
          alertify.error(err.response.data.message);
        });
    } else {
      createEquipment(
        {
          ...formToSave,
        },
        props.organizationId
      )
        .then((res) => {
          alertify.success("Equipment Added.");
          props.handleModalClose(true, res.data.id);
        })
        .catch((err) => {
          alertify.error(err.response.data.message);
        });
    }
  };

  const handleDeleteEquipment = () => {
    deleteEquipment(props.entity.id).then((res) => {
      alertify.success("Equipment Deleted.");
      props.handleModalClose(true);
    });
  };

  return (
    <>
      <Form className="card dsmodal-main lynx-modal">
        <Card.Body>
          <Card.Title>
            {!_.isEmpty(props.entity) ? "Edit Equipment" : "Add Equipment"}

            <Icon
              name="x"
              onClick={() => props.handleModalClose(false)}
              className="float-right pointer"
            ></Icon>
          </Card.Title>

          <Grid.Row>
            <>
              <Grid.Col md={12} width={12}>
                <Form.Group label="Equipment Name" isRequired>
                  <Form.Input
                    name="name"
                    onChange={handleInputChange}
                    value={formState.name}
                    error={formState.nameError}
                  ></Form.Input>
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={12} width={12}>
                <Form.Group label="Asset" isRequired>
                  <Form.Select
                    name="assetId"
                    disabled={props.defaultAssetId ? true : false}
                    onChange={handleInputChange}
                    value={formState.assetId}
                    error={formState.assetIdError}
                  >
                    <option value={""}></option>
                    {assets.map((asset) => (
                      <option value={asset.id} key={asset.id}>
                        {asset.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Grid.Col>
              <Grid.Col md={12} width={12}>
                <Form.Group label="Description">
                  <LynxTextArea
                    name="description"
                    onChange={handleInputChange}
                    value={formState.description}
                  ></LynxTextArea>
                </Form.Group>
              </Grid.Col>
            </>
          </Grid.Row>
        </Card.Body>
        <Card.Footer>
          <div>
            <>
              {!_.isEmpty(props.entity) && (
                <Button
                  disabled={props.entity.isInUse}
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setShowDeleteDialog(true);
                  }}
                >
                  Delete Equipment
                </Button>
              )}

              <Button
                variant="contained"
                className="float-right"
                onClick={saveForm}
              >
                {!_.isEmpty(props.entity) ? "Save Equipment" : "Add Equipment"}
              </Button>
            </>
          </div>
          {props.entity.isInUse && (
            <div
              className="ml-1 text-red"
              style={{
                height: 35,
                verticalAlign: "top",
                display: "table-cell",
                fontSize: 14,
              }}
            >
              <span className=""> Unable to delete when in use</span>
            </div>
          )}
        </Card.Footer>
        {showDeleteDialog && (
          <LynxDialog
            open={showDeleteDialog}
            handleClose={() => setShowDeleteDialog(false)}
            handleDelete={() => {
              handleDeleteEquipment();
            }}
            title={"Delete Equipment?"}
            description={"This cannot be undone."}
          />
        )}
      </Form>
    </>
  );
}

import Breadcrumbs from "@mui/material/Breadcrumbs";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import alertify from "alertifyjs";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, Dimmer, Grid } from "tabler-react";
import { getLogo, uploadLogo } from "../../../services/organization-service";
import { getUsersLookups } from "../../../services/users";
import { LynxDataGrid } from "../../data-grid/LynxDataGrid";
import { UserModal } from "./user-modal";
import { getDepartments } from "services/department-service";
import { IconButton, Modal, Tooltip } from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { DepartmentModal } from "./department-modal";
import EditIcon from "@mui/icons-material/Edit";
import { roleMatch } from "actions/auth";
import { UserRoles } from "types/enums";
import { useDispatch, useSelector } from "react-redux";
import { setUsers } from "reducers/lookups";

export function OrganizationSettings(props) {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.lookups);
  const [logo, setLogo] = useState(null);
  const [logoLoading, setLogoLoading] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState();
  const [isDeptModalOpen, setIsDeptModalOpen] = useState(false);

  const history = useHistory();
  useEffect(() => {
    loadLogo();
    loadDepartments();
  }, []);

  const loadLogo = () => {
    setLogoLoading(true);
    getLogo()
      .then((res) => {
        setLogo(res.data);
        setLogoLoading(false);
      })
      .catch((err) => {
        setLogoLoading(false);
      });
  };

  const loadDepartments = () => {
    getDepartments().then((res) => {
      setDepartments(res.data);
    });
  };

  const loadUserList = () => {
    getUsersLookups().then((res) => {
      dispatch(setUsers(res.data));
    });
  };

  let defaultColumns = [
    {
      field: "email",
      headerName: "Email",
      width: 250,
      type: "string",
    },
    {
      field: "firstName",
      headerName: "First Name",
      width: 250,
      type: "string",
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 250,
      type: "string",
    },
    {
      field: "isActive",
      headerName: "Active?",
      width: 250,
      type: "boolean",
    },
    {
      field: "isAdmin",
      headerName: "Admin?",
      width: 250,
      type: "boolean",
    },
  ];

  const departmentColumns = [
    {
      field: "edit",
      headerName: " ",
      width: 45,
      type: "",
      sortable: false,
      renderCell: (metadata) => (
        <Tooltip title="Edit">
          <IconButton
            onClick={() => {
              setSelectedDepartment(metadata.row);
              setIsDeptModalOpen(true);
            }}
            aria-label="Edit"
            size="small"
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      type: "string",
      sortable: true,
    },
    {
      field: "description",
      headerName: "Description",
      width: 200,
      type: "string",
      sortable: true,
    },
  ];

  const handleLogoUpload = (e) => {
    const selectedFiles = e.target.files;
    const file = selectedFiles[0];
    if (file) {
      setLogoLoading(true);
      let form = new FormData();
      form.append("file", file);
      uploadLogo(form).then(() => {
        loadLogo();
        alertify.success("Logo Uploaded");
      });
    }
  };
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };

  const handleOnCloseDeptModal = () => {
    setSelectedDepartment(undefined);
    setIsDeptModalOpen(false);
  };

  const handleRefresh = () => {
    loadDepartments();
    loadUserList();
  };

  return (
    <Grid>
      <Paper>
        <Grid.Row className="ml-0 mr-0 subpage-header-row-breadcrumbs mb-5">
          <Grid.Col width={12}>
            <div className="d-flex h-100">
              <Typography
                className="align-self-center"
                variant="h3"
                component="div"
              >
                Organization Settings
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col lg={12} width={12} className="">
            <Breadcrumbs aria-label="breadcrumb" className="mb-2">
              <Link
                underline="hover"
                color="inherit"
                href="#"
                onClick={(e) => handleNavigateTo(e, "/settings")}
              >
                Settings
              </Link>

              <Typography color="text.primary">
                Organization Settings
              </Typography>
            </Breadcrumbs>
          </Grid.Col>
        </Grid.Row>
      </Paper>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Organization Logo
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              The organization logo is used in reports and in the community
              portal (if applicable).
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <Card>
            <Dimmer active={logoLoading} loader>
              {(!logo || logo.size == 0) && !logoLoading && (
                <div className="m-2">No logo uploaded</div>
              )}
              {logo && logo.size > 0 && !logoLoading && (
                <img
                  src={`${URL.createObjectURL(logo)}`}
                  loading="lazy"
                  style={{ width: "200px" }}
                  className="mb-1"
                />
              )}

              <Card.Footer>
                <Button
                  variant="outlined"
                  sx={{ width: 100 }}
                  component="label"
                >
                  Upload
                  <input
                    hidden
                    accept="image/*"
                    type="file"
                    onChange={handleLogoUpload}
                  />
                </Button>
              </Card.Footer>
            </Dimmer>
          </Card>
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col md={2} width={12}>
          <div className="">
            <Typography variant="h5" component="div">
              Users
            </Typography>
            <Typography variant="body2" className="mt-2" component="div">
              Update user details and configure user permissions here.
            </Typography>
          </div>
        </Grid.Col>
        <Grid.Col md={10} width={12}>
          <div style={{ height: 630, width: "100%" }}>
            <LynxDataGrid
              columns={defaultColumns}
              data={users}
              entityName={"User"}
              {...props}
              hasEditButton
              handleRefresh={handleRefresh}
              entityModal={UserModal}
              passEntityOnClick={true}
              disableAddButton={true}
              localStorageName="users"
              isForSettings
            />
          </div>
        </Grid.Col>
      </Grid.Row>
      {roleMatch([UserRoles.IncidentsUser]) && (
        <Grid.Row>
          <Grid.Col md={2} width={12}>
            <div className="">
              <Typography variant="h5" component="div">
                Departments
              </Typography>
              <Typography variant="body2" className="mt-2" component="div">
                Manage departments and assign members to departments
              </Typography>
            </div>
          </Grid.Col>
          <Grid.Col md={10} width={12}>
            <Card>
              <Card.Header className="justify-content-end">
                <Button
                  variant="contained"
                  onClick={() => {
                    setIsDeptModalOpen(true);
                  }}
                >
                  Add
                </Button>
              </Card.Header>

              <div style={{ height: 630, width: "100%" }}>
                <DataGridPro
                  columns={departmentColumns}
                  rows={departments}
                  disableColumnReorder
                  disableColumnFilter
                  disableColumnMenu
                  disableSelectionOnClick
                  disableChildrenSorting
                />
              </div>
            </Card>
          </Grid.Col>
          {isDeptModalOpen && (
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              open={isDeptModalOpen}
              onClose={handleOnCloseDeptModal}
            >
              <DepartmentModal
                selectedDepartment={selectedDepartment}
                handleModalClose={handleOnCloseDeptModal}
                onSaveEffect={() => loadDepartments()}
                userList={users}
              />
            </Modal>
          )}
        </Grid.Row>
      )}
    </Grid>
  );
}

import SecurityIcon from "@mui/icons-material/Security";
import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import SummarizeIcon from "@mui/icons-material/Summarize";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import _ from "lodash";
import queryString from "query-string";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Dimmer, Grid } from "tabler-react";
import lynxColors from "../../../modules/lynxColors";
import { getIncident } from "../../../services/incident-service";
import PageNotFound from "../../PageNotFound";
import { IncidentActions } from "./incident-actions";
import { IncidentAttachments } from "./incident-attachments";
import { IncidentFinalReview } from "./incident-final-review";
import { IncidentForm } from "./incident-form";
import "./incident-form.css";
import { IncidentInvestigation } from "./incident-investigation";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { IncidentInvestigationParticipants } from "./incident-investigation-participants";
import { IncidentAudit } from "./incident-audit";
import { runIncidentReport } from "../../../services/reports-service";
import { saveAs } from "file-saver";
import { IncidentReportTypes } from "../../../types/enums";
import { canViewInvestigation } from "services/incident-assignment-service";
export function IncidentContainer(props) {
  const [incident, setIncident] = useState({});
  const [loading, setLoading] = useState(true);
  const [showNotFound, setShowNotFound] = useState(false);
  const [view, setView] = useState("incident");
  const history = useHistory();
  const location = useLocation();
  var account = useSelector((state) => state.account);
  let incidentStatus = _.toLower(incident.status);
  const [size, setSize] = useState([0, 0]);
  const targetRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [reportsAnchorEl, setReportsAnchorEl] = React.useState(null);
  const reportsOpen = Boolean(reportsAnchorEl);
  const handleClick = (event) => {
    setReportsAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setReportsAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  useEffect(() => {
    if (props.match.params.id) {
      loadIncident();
      const values = queryString.parse(props.location.search);
      if (!values.view) {
        props.history.push(`/incidents/${props.match.params.id}?view=incident`);
      }
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const values = queryString.parse(props.location.search);
    setView(values.view ?? "incident");
  }, [location]);
  useEffect(() => {
    if (account && !account.isLoading && !_.isEmpty(incident)) {
      setLoading(false);
    }
  }, [account, incident]);

  useLayoutEffect(() => {
    function updateSize() {
      var width = targetRef.current.offsetWidth;
      setSize([width, targetRef.current.offsetHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  const isExistingIncident = () => {
    return props.match.params.id;
  };

  const menuOptions = () => {
    let baseOptions = [{ label: "INITIAL REPORT", view: "incident" }];
    if (
      incident.investigationRequired &&
      canViewInvestigation(incident, account.id)
    ) {
      baseOptions = [
        ...baseOptions,
        { label: "INVESTIGATION", view: "investigation" },
      ];
    }
    baseOptions = [...baseOptions, { label: "ACTIONS", view: "actions" }];
    if (showFinalReview()) {
      baseOptions = [
        ...baseOptions,
        { label: "FINAL REVIEW", view: "final-review" },
      ];
    }

    baseOptions = [
      ...baseOptions,
      { label: "ATTACHMENTS", view: "attachments" },
    ];

    return baseOptions;
  };

  const handleMenuItemClick = (event, item) => {
    setView(item.view);
    setAnchorEl(null);
  };

  const loadIncident = () => {
    getIncident(props.match.params.id)
      .then((res) => {
        setIncident(res.data);
      })
      .catch((err) => {
        setShowNotFound(true);
      });
  };
  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };

  const handleTabChange = (e, newValue) => {
    setView(newValue);
    window.history.pushState({}, "", location.pathname + `?view=${newValue}`);
  };

  const showTabs = () => {
    if (!isExistingIncident()) {
      return false;
    }
    if (incidentStatus == "pending review") {
      return false;
    }
    if (size[0] < 650) return false;

    return true;
  };

  const showMenu = () => {
    if (size[0] < 650) return true;
    return false;
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const showFinalReview = () => {
    return (
      incidentStatus == "final review" ||
      incidentStatus == "closed" ||
      incidentStatus == "pending actions"
    );
  };

  const handleRunReport = (reportType) => {
    handleClose();
    runIncidentReport(incident.id, reportType).then((res) => {
      saveAs(res.data, `${incident.incidentNumber}_${reportType}.pdf`);
    });
  };
  if (showNotFound) {
    return <PageNotFound message="Incident not found" />;
  } else
    return (
      <Grid>
        <div ref={targetRef}>
          <Dimmer active={loading} loader>
            <Paper>
              <Grid.Row className="ml-0 mr-0">
                <Grid.Col lg={12} width={12} className="">
                  <div className="d-flex">
                    <Typography variant="h3" component="div">
                      {isExistingIncident()
                        ? incident.incidentNumber
                        : "New Incident Report"}
                    </Typography>
                    {isExistingIncident() && (
                      <>
                        <div className="align-self-center ml-5">
                          {getStatusChip(incident)}
                        </div>

                        <div className="align-self-center ml-2 mt-3 mb-2">
                          <Button
                            variant="outlined"
                            aria-controls={
                              reportsOpen ? "basic-menu" : undefined
                            }
                            className="mb-1"
                            aria-haspopup="true"
                            aria-expanded={reportsOpen ? "true" : undefined}
                            onClick={handleClick}
                            startIcon={<DescriptionOutlinedIcon />}
                          >
                            Reports
                          </Button>
                          <Menu
                            id="basic-menu"
                            anchorEl={reportsAnchorEl}
                            open={reportsOpen}
                            onClose={handleClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                handleRunReport(
                                  IncidentReportTypes.InitialIncidentReport
                                );
                              }}
                            >
                              <ListItemIcon>
                                <SummarizeIcon fontSize="small" />
                              </ListItemIcon>
                              <ListItemText>
                                Initial Incident Report
                              </ListItemText>
                            </MenuItem>
                            {canViewInvestigation(incident, account.id) && (
                              <>
                                <MenuItem
                                  onClick={() => {
                                    handleRunReport(
                                      IncidentReportTypes.IncidentInvestigationReport
                                    );
                                  }}
                                >
                                  <ListItemIcon>
                                    <SummarizeIcon fontSize="small" />
                                  </ListItemIcon>
                                  <ListItemText>
                                    Incident Investigation Report
                                  </ListItemText>
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    handleRunReport(
                                      IncidentReportTypes.FullIncidentReport
                                    );
                                  }}
                                >
                                  <ListItemIcon>
                                    <SummarizeIcon fontSize="small" />
                                  </ListItemIcon>
                                  <ListItemText>
                                    Full Incident Report
                                  </ListItemText>
                                </MenuItem>
                              </>
                            )}
                          </Menu>
                        </div>
                      </>
                    )}
                  </div>
                </Grid.Col>{" "}
                <Grid.Col lg={12} width={12} className="">
                  <Breadcrumbs
                    aria-label="breadcrumb"
                    className="mb-2 d-print-none"
                  >
                    <Link
                      underline="hover"
                      color="inherit"
                      href="#"
                      onClick={(e) => handleNavigateTo(e, "/incidents")}
                    >
                      Incidents
                    </Link>

                    <Typography color="text.primary">
                      {isExistingIncident()
                        ? incident.incidentNumber
                        : "New Incident Report"}
                    </Typography>
                  </Breadcrumbs>
                </Grid.Col>
              </Grid.Row>
              {showMenu() && isExistingIncident() && (
                <Grid.Row>
                  <Box
                    sx={{
                      width: "100%",

                      borderTop: `1px solid ${lynxColors.gray}`,
                    }}
                  >
                    <List
                      component="nav"
                      aria-label="Device settings"
                      sx={{ bgcolor: "background.paper" }}
                    >
                      <ListItem
                        button
                        id="lock-button"
                        aria-haspopup="listbox"
                        aria-controls="lock-menu"
                        aria-label="when device is locked"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClickListItem}
                      >
                        <ListItemText
                          primary={
                            menuOptions().find((x) => x.view == view) &&
                            menuOptions().find((x) => x.view == view).label
                          }
                        />
                      </ListItem>
                    </List>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleMenuClose}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      {menuOptions().map((option, index) => (
                        <MenuItem
                          key={option.view}
                          selected={view === option.view}
                          onClick={(event) =>
                            handleMenuItemClick(event, option)
                          }
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Box>
                </Grid.Row>
              )}
              {showTabs() && (
                <Grid.Row>
                  <Box
                    className="d-print-none"
                    sx={{
                      width: "100%",

                      borderTop: `1px solid ${lynxColors.gray}`,
                    }}
                  >
                    <Tabs
                      value={view}
                      onChange={handleTabChange}
                      sx={{ height: "50px" }}
                    >
                      <Tab
                        label="Initial Report"
                        value="incident"
                        sx={{ height: "50px" }}
                      />
                      {incident.investigationRequired && (
                        <Tab
                          label={"Investigation"}
                          icon={<SecurityIcon fontSize="small" />}
                          iconPosition="end"
                          value="investigation"
                          disabled={!canViewInvestigation(incident, account.id)}
                          sx={{ height: "50px", minHeight: "50px" }}
                        />
                      )}
                      {incident.investigationRequired && (
                        <Tab
                          label={"Participants"}
                          icon={<SecurityIcon fontSize="small" />}
                          iconPosition="end"
                          value="participants"
                          disabled={!canViewInvestigation(incident, account.id)}
                          sx={{ height: "50px", minHeight: "50px" }}
                        />
                      )}

                      <Tab
                        label="Actions"
                        value="actions"
                        sx={{ height: "50px" }}
                      />
                      {showFinalReview() && (
                        <Tab
                          label="Final Review"
                          value="final-review"
                          disabled={!showFinalReview()}
                          sx={{ height: "50px" }}
                        />
                      )}
                      <Tab
                        label="Attachments"
                        value="attachments"
                        sx={{ height: "50px" }}
                      />
                      <Tab
                        label="Audit"
                        iconPosition="end"
                        icon={<SecurityIcon fontSize="small" />}
                        value="audit"
                        sx={{ height: "50px", minHeight: "50px" }}
                        disabled={!canViewInvestigation(incident, account.id)}
                      />
                    </Tabs>
                  </Box>
                </Grid.Row>
              )}
            </Paper>
          </Dimmer>

          <Grid.Row>
            {view == "incident" &&
              (!_.isEmpty(incident) || !isExistingIncident()) && (
                <IncidentForm
                  {...props}
                  showAttachments={
                    !isExistingIncident() ||
                    _.toLower(incidentStatus) == "pending review"
                  }
                  incident={incident}
                  isExistingIncident={isExistingIncident()}
                  updateIncident={(incident) => setIncident(incident)}
                />
              )}
            {view == "actions" && !_.isEmpty(incident) && (
              <IncidentActions incident={incident} />
            )}
            {view == "investigation" &&
              !_.isEmpty(incident) &&
              incident.investigationRequired &&
              canViewInvestigation(incident, account.id) && (
                <IncidentInvestigation
                  incident={incident}
                  updateIncident={(incident) => setIncident(incident)}
                />
              )}
            {view == "participants" &&
              !_.isEmpty(incident) &&
              incident.investigationRequired &&
              canViewInvestigation(incident, account.id) && (
                <IncidentInvestigationParticipants
                  incident={incident}
                  updateIncident={(incident) => setIncident(incident)}
                />
              )}
            {view == "final-review" &&
              !_.isEmpty(incident) &&
              showFinalReview() && (
                <IncidentFinalReview
                  incident={incident}
                  updateIncident={(incident) => setIncident(incident)}
                />
              )}
            {view == "attachments" && !_.isEmpty(incident) && (
              <IncidentAttachments incident={incident} {...props} />
            )}
            {view == "audit" && (
              <IncidentAudit incident={incident} {...props} />
            )}
          </Grid.Row>
        </div>
      </Grid>
    );
}

export const getStatusChip = (incident, size) => {
  if (_.toLower(incident.status) == "pending review") {
    return (
      <Chip
        label="Pending Review"
        size={size}
        sx={{ backgroundColor: lynxColors.harvestOrange, color: "white" }}
      />
    );
  }
  if (_.toLower(incident.status) == "investigation") {
    return <Chip label="Investigation" size={size} color="primary" />;
  }
  if (_.toLower(incident.status) == "final review") {
    return (
      <Chip
        label="Final Review"
        size={size}
        sx={{ backgroundColor: lynxColors.harvestOrange, color: "white" }}
      />
    );
  }
  if (_.toLower(incident.status) == "pending actions") {
    return (
      <Chip
        label="Pending Actions"
        size={size}
        sx={{ backgroundColor: lynxColors.harvestOrange, color: "white" }}
      />
    );
  }

  return <Chip label="Closed" size={size} color="primary" />;
};

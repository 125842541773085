import React, { useState, useEffect } from "react";
import { Grid, Card, Form, Dimmer } from "tabler-react";
import _ from "lodash";
import { LocationSelectionControl } from "../../location-selection-control";
import Button from "@mui/material/Button";
import { LynxDialog } from "../../lynx-dialog";
import { roleMatch } from "../../../actions/auth";
import { validationService } from "../../../services/validation";
import alertify from "alertifyjs";
import * as jsonpatch from "fast-json-patch";
import Typography from "@mui/material/Typography";
import {
  getMonitoringLocation,
  createMonitoringLocation,
  patchMonitoringLocation,
  deleteMonitoringLocation,
} from "../../../services/monitoring-location-service";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import mapboxgl from "mapbox-gl";
import { getAssetLookups } from "../../../services/assets";
import { LookupTypes, UserRoles } from "../../../types/enums";
import { useHistory } from "react-router-dom";
import { getLookups } from "../../../services/lookup";
import { LynxTextArea } from "components/form-controls/lynx-form-controls";
export function MonitoringLocationForm(props) {
  const initialForm = {
    assetId: "",
    name: "",
    description: "",
    nameError: "",
    latitude: "",
    longitude: "",
    monitoringLocationTypeId: "",
    monitoringLocationTypeIdError: "",
    isWildlifeCamera: false,
    isTimeSeriesLocation: false,
  };
  const [isLoading, setIsLoading] = useState(true);
  const [formState, setFormState] = useState(initialForm);
  const [showDelete, setShowDelete] = useState(false);
  const [initialFormState, setInitialFormState] = useState({});
  const [defaultLatitude, setDefaultLatitude] = useState("");
  const [defaultLongitude, setDefaultLongitude] = useState("");
  const [assetLookups, setAssetLookups] = useState([]);
  const [locationTypes, setLocationTypes] = useState([]);
  const [monitoringLocation, setMonitoringLocation] = useState({});
  const history = useHistory();
  const isExistingLocation = () => {
    return props.match.params.monLocId;
  };

  // prettier-ignore
  // eslint-disable-next-line import/no-webpack-loader-syntax
  mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
  useEffect(() => {
    if (isExistingLocation()) {
      getMonitoringLocation(props.match.params.monLocId).then((res) => {
        setFormStateFromProps(res.data);
        setIsLoading(false);
        setMonitoringLocation(res.data);
      });
    } else {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    let asset = assetLookups.find((x) => x.id == formState.assetId);
    if (asset && !isExistingLocation()) {
      setDefaultLatitude(asset.centroidLatitude);
      setDefaultLongitude(asset.centroidLongitude);
    }
  }, [formState.assetId]);

  useEffect(() => {
    loadLookups();
  }, []);

  const handleInputChange = (e) => {
    let newState = { ...formState };
    const name = e.target.name;
    const value =
      e.target.type == "checkbox" ? e.target.checked : e.target.value;
    _.set(newState, name, value);
    setFormState(newState);
  };
  const loadLookups = () => {
    getAssetLookups()
      .then((res) => {
        setAssetLookups(res.data);
      })
      .catch(() => {});
    getLookups(LookupTypes.MonitoringLocationType).then((res) => {
      setLocationTypes(res.data);
    });
  };
  const handleLatLongChange = (lat, long) => {
    setFormState((existingState) => {
      return { ...existingState, latitude: lat, longitude: long };
    });
  };

  const setFormStateFromProps = (monLoc) => {
    let newState = {
      name: monLoc.name,
      description: monLoc.description,
      longitude: monLoc.longitude,
      latitude: monLoc.latitude,
      assetId: monLoc.assetId,
      monitoringLocationNumber: monLoc.monitoringLocationNumber,
      monitoringLocationTypeId: monLoc.monitoringLocationTypeId,
      isWildlifeCamera: monLoc.isWildlifeCamera,
      isTimeSeriesLocation: monLoc.isTimeSeriesLocation,
    };
    setInitialFormState(newState);
    setFormState(newState);
    setDefaultLatitude(monLoc.latitude);
    setDefaultLongitude(monLoc.longitude);
  };

  const handleNavigateTo = (e, url) => {
    if (e && e != null) {
      e.preventDefault();
    }
    history.push(url);
  };

  const saveForm = () => {
    //validate here
    if (!validateDataForSave()) {
      return;
    }
    //unset errors
    let formToSave = validationService.unsetErrors(
      formState,
      "nameError",
      "assetIdError",
      "monitoringLocationTypeIdError"
    );
    if (!isExistingLocation()) {
      createMonitoringLocation(formToSave)
        .then((res) => {
          alertify.success(`Monitoring Location Created`);
          handleNavigateTo(null, "/monitoring?view=locations");
        })
        .catch((err) => {
          alertify.error(err.response.data.message);
        });
    }
    if (isExistingLocation()) {
      var diff = jsonpatch.compare(initialFormState, formToSave);
      patchMonitoringLocation(props.match.params.monLocId, diff)
        .then((res) => {
          alertify.success(`Monitoring Location Saved`);
          handleNavigateTo(null, "/monitoring?view=locations");
        })
        .catch((err) => {
          alertify.error(err.response.data.message);
        });
    }
  };
  const handleDelete = () => {
    setShowDelete(true);
  };

  const validateDataForSave = () => {
    let newState = { ...formState };
    let isFormValid = false;

    validationService.validateRequiredField(
      newState,
      "name",
      "nameError",
      "Name"
    );

    validationService.validateRequiredField(
      newState,
      "assetId",
      "assetIdError",
      "Asset"
    );

    validationService.validateRequiredField(
      newState,
      "monitoringLocationTypeId",
      "monitoringLocationTypeIdError",
      "Type"
    );
    isFormValid = !validationService.hasError(
      newState,
      "nameError",
      "assetIdError",
      "monitoringLocationTypeIdError"
    );

    if (!isFormValid) {
      setFormState(newState);
      alertify.error("Form is not valid for saving.");
    }
    return isFormValid;
  };

  const deleteItem = () => {
    deleteMonitoringLocation(props.match.params.monLocId)
      .then(() => {
        alertify.success(`Monitoring Location Deleted`);
        handleNavigateTo(null, "/monitoring?view=locations");
      })
      .catch((err) => {
        alertify.error(err.response.data.message);
      });
  };

  return (
    <Grid>
      <Dimmer active={isLoading} loader>
        <Paper>
          <Grid.Row className="ml-0 mr-0">
            <Grid.Col lg={12} width={12} className="">
              <div className="d-flex">
                <Typography
                  variant="h3"
                  className="form-header-text"
                  component="div"
                >
                  {isExistingLocation()
                    ? initialFormState.monitoringLocationNumber
                    : "Add Monitoring Location"}
                </Typography>
              </div>
            </Grid.Col>
            <Grid.Col lg={12} width={12} className="">
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  color="inherit"
                  href="#"
                  onClick={(e) => handleNavigateTo(e, "/monitoring")}
                >
                  Monitoring
                </Link>
                <Typography color="text.primary">
                  {isExistingLocation()
                    ? initialFormState.monitoringLocationNumber
                    : "Add Monitoring Location"}
                </Typography>
              </Breadcrumbs>
            </Grid.Col>
          </Grid.Row>
        </Paper>
        <Grid.Row>
          <Container className="mt-2" maxWidth="xl">
            <Form className="card">
              <Card.Body>
                <Grid.Row>
                  <Grid.Col md={12} width={12}>
                    <Form.Group isRequired label="Asset">
                      <Form.Select
                        name="assetId"
                        onChange={handleInputChange}
                        value={props.asset ? props.asset.id : formState.assetId}
                        error={formState.assetIdError}
                        disabled={props.asset}
                      >
                        <option value={""}></option>
                        {assetLookups.map((asset) => (
                          <option value={asset.id} key={asset.id}>
                            {asset.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col md={12} width={12}>
                    <Form.Group isRequired label="Name">
                      <Form.Input
                        name="name"
                        onChange={handleInputChange}
                        value={formState.name}
                        error={formState.nameError}
                      ></Form.Input>
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col md={12} width={12}>
                    <Form.Group isRequired label="Type">
                      <Form.Select
                        name="monitoringLocationTypeId"
                        onChange={handleInputChange}
                        value={formState.monitoringLocationTypeId}
                        error={formState.monitoringLocationTypeIdError}
                      >
                        <option value={""}></option>
                        {locationTypes.map((type) => (
                          <option value={type.id} key={type.id}>
                            {type.code}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Grid.Col>
                  <Grid.Col md={12} width={12}>
                    <Form.Group label="Description">
                      <LynxTextArea
                        name="description"
                        onChange={handleInputChange}
                        value={formState.description}
                      ></LynxTextArea>
                    </Form.Group>
                  </Grid.Col>
                  {roleMatch([UserRoles.InternalUser]) && (
                    <>
                      <Grid.Col md={6} width={12}>
                        <Form.Group label="">
                          <Form.Checkbox
                            label="Wildlife Camera?"
                            name={`isWildlifeCamera`}
                            checked={formState.isWildlifeCamera}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Grid.Col>
                      <Grid.Col md={6} width={12}>
                        <Form.Group label="">
                          <Form.Checkbox
                            label="Timeseries location?"
                            name={`isTimeSeriesLocation`}
                            checked={formState.isTimeSeriesLocation}
                            onChange={handleInputChange}
                          />
                        </Form.Group>
                      </Grid.Col>
                    </>
                  )}

                  <LocationSelectionControl
                    defaultLatitude={defaultLatitude}
                    defaultLongitude={defaultLongitude}
                    handleLatLongChange={handleLatLongChange}
                    handleLocationTouched={() => {}}
                  />
                </Grid.Row>
              </Card.Body>
              <Card.Footer>
                {!isExistingLocation() && (
                  <Button
                    variant="contained"
                    className="float-right"
                    onClick={saveForm}
                  >
                    {`Add`}
                  </Button>
                )}
                {isExistingLocation() && (
                  <div>
                    <div>
                      {roleMatch([UserRoles.Admin]) &&
                        !monitoringLocation.isWildlifeCamera &&
                        !monitoringLocation.IsTimeSeriesLocation && (
                          <Button
                            variant="contained"
                            color="error"
                            className="float-left"
                            onClick={handleDelete}
                          >
                            {`Delete`}
                          </Button>
                        )}
                    </div>

                    <Button
                      variant="contained"
                      className="float-right"
                      onClick={saveForm}
                    >
                      {`Save`}
                    </Button>
                  </div>
                )}
                <LynxDialog
                  open={showDelete}
                  handleClose={() => setShowDelete(false)}
                  handleDelete={deleteItem}
                  title={`Delete Monitoring Location?`}
                  description={
                    "All associated monitoring events will also be deleted."
                  }
                />
              </Card.Footer>
            </Form>
          </Container>
        </Grid.Row>
      </Dimmer>
    </Grid>
  );
}

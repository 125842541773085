import Container from "@mui/material/Container";
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Card, Dimmer, Form, Grid } from "tabler-react";
import "./incident-form.css";
import EditIcon from "@mui/icons-material/Edit";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import { CustomFieldTypes, LookupTypes } from "../../../types/enums";
import { InspectionFormField } from "../inspections/inspection-form-field";
import { IncidentParticipants } from "./incident-participants";
import { getLookups } from "../../../services/lookup";
import {
  getErrorFieldArray,
  getFullStateFromInspectionDef,
  getInspectionForms,
  getStateFromExistingInspection,
} from "../../../services/inspection-form-service";
import { validationService } from "../../../services";
import { convertInspectionStateToValueObject } from "./../../../services/inspection-form-service";
import {
  createWitnessStatement,
  updateIncidentParticipants,
} from "../../../services/incident-service";
import alertify from "alertifyjs";
import { useSelector } from "react-redux";

export function IncidentInvestigationParticipants(props) {
  const initialForm = {
    affectedPartiesError: "",
    userParticipants: [],
    userWitnesses: [],
  };

  const { users } = useSelector((state) => state.lookups);
  
  const [inspectionState, setInspectionState] = useState({});
  const [formState, setFormState] = useState(initialForm);
  const [affectedParties, setAffectedParties] = useState([]);
  const [witnesses, setWitnesses] = useState([]);
  const [incidentParticipantTypes, setIncidnetParticipantTypes] = useState([]);
  const [witnessForm, setWitnessForm] = useState([]);
  const [toggleAddStatement, setToggleAddStatement] = useState(false);
  const [existingWitnessStatements, setExistingWitnessStatements] = useState(
    []
  );
  const [selectedWitness, setSelectedWitness] = useState({});

  const isInitiallyLocked = props.incident.status.toLowerCase() == "closed";
  const [isLocked, setIsLocked] = useState({
    parties: isInitiallyLocked,
    witness: isInitiallyLocked,
  });

  useEffect(() => {
    getInspectionForms({ onlyWitnessForms: true }).then((res) => {
      setWitnessForm(res.data[0]);
    });
    getLookups(LookupTypes.IncidentParticipantType).then((res) => {
      setIncidnetParticipantTypes(res.data);
    });
  }, []);

  useEffect(() => {
    if (!_.isEmpty(props.incident)) {
      setAffectedParties(
        props.incident.incidentParticipants.filter(
          (x) => !x.userId && !x.isWitness
        )
      );
      setWitnesses(
        props.incident.incidentParticipants.filter(
          (x) => !x.userId && x.isWitness
        )
      );
    }
  }, [props.incident]);

  useEffect(() => {
    if (!_.isEmpty(props.incident) && !_.isEmpty(users)) {
      let witnessIds = props.incident.incidentParticipants
        .filter((x) => x.isWitness && x.userId)
        .map((a) => a.userId);
      let witnesses = users.filter((a) => witnessIds.includes(a.id));

      let participantIds = props.incident.incidentParticipants
        .filter((x) => !x.isWitness && x.userId)
        .map((a) => a.userId);
      let participants = users.filter((a) => participantIds.includes(a.id));
      setFormState((existing) => {
        return {
          ...existing,
          userParticipants: participants,
          userWitnesses: witnesses,
        };
      });

      setExistingWitnessStatements(
        props.incident.incidentParticipants.filter((x) => x.isWitness)
      );
    }
  }, [props.incident, users]);

  useEffect(() => {
    if (!_.isEmpty(witnessForm) && !_.isEmpty(selectedWitness)) {
      let fullState = getFullStateFromInspectionDef(
        witnessForm.inspectionFormFields
      );
      //merge existing values
      let existingInspectionState = getStateFromExistingInspection(
        selectedWitness.inspectionFormFieldValues
      );
      fullState = { ...fullState, ...existingInspectionState };
      setInspectionState(fullState);
    }
  }, [witnessForm, selectedWitness]);

  const handleInspectionInputChange = (e, sigName, sigInput) => {
    let newState = { ...inspectionState };
    let name = sigName ?? e.target.name;
    const editedField = witnessForm.inspectionFormFields.find(
      (x) => x.name == name
    );
    let value = sigName
      ? sigInput
      : e.target.type == "checkbox"
      ? e.target.checked
      : editedField && editedField.type == CustomFieldTypes.MultiSelect
      ? e.target.value.join("|")
      : e.target.value;
    _.set(newState, name, value);

    setInspectionState(newState);
  };

  const handleInputChange = (e) => {
    let newState = { ...formState };
    let { name, value } = e.target;
    _.set(newState, name, value);

    setFormState(newState);
  };

  const handleFormSave = (saveAction) => {
    const errorFields = getErrorFieldArray(witnessForm.inspectionFormFields);
    let inspectionFormValuesToSave = validationService.unsetFields(
      inspectionState,
      ...errorFields
    );
    var objectListToSave = convertInspectionStateToValueObject(
      witnessForm.inspectionFormFields,
      inspectionFormValuesToSave
    );

    createWitnessStatement(props.incident.id, {
      id: selectedWitness.id,
      inspectionFormFieldValues: objectListToSave,
    }).then((res) => {
      props.updateIncident(res.data);
      if (saveAction == "exit") {
        setToggleAddStatement(false);
      }
      alertify.success("Witness statement saved.");
    });
  };

  const getFormButtons = () => {
    return (
      <div className="float-right">
        <Button variant="contained" className="mr-2" onClick={handleFormSave}>
          Save
        </Button>
        <Button
          variant="contained"
          className="mr-2"
          onClick={() => handleFormSave("exit")}
        >
          Save and Exit
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            setToggleAddStatement(false);
          }}
        >
          Exit Without Saving
        </Button>
      </div>
    );
  };

  const handleStatementClick = (statement) => {
    setToggleAddStatement(true);
    setSelectedWitness(statement);
  };

  const handleParticipantsSave = () => {
    let formToSave = _.cloneDeep(formState);
    formToSave.incidentParticipants = [...witnesses, ...affectedParties];
    formToSave.userParticipants = formToSave.userParticipants.map((x) => x.id);
    formToSave.userWitnesses = formToSave.userWitnesses.map((x) => x.id);
    var dto = {
      incidentParticipants: formToSave.incidentParticipants,
      userWitnesses: formToSave.userWitnesses,
      userParticipants: formToSave.userParticipants,
    };
    updateIncidentParticipants(props.incident.id, dto).then((res) => {
      props.updateIncident(res.data);
      alertify.success("Participants saved.");
      resetIsLocked();
    });
  };

  
  const toggleLock = (name) => {
    const newState = { ...isLocked };
    const current = _.get(newState, name);
    _.set(newState, name, !current);
    setIsLocked(newState);
  };

  const resetIsLocked = () => {
    setIsLocked({
      parties: isInitiallyLocked,
      witness: isInitiallyLocked,
    });
  };

  if (toggleAddStatement) {
    return (
      <Container className="form-container mb-5" maxWidth="xl">
        <Form className="card mb-0">
          <Card.Header>
            <Card.Title>{selectedWitness.name} - Witness Statement</Card.Title>
            <div className="ml-auto">{getFormButtons()}</div>
          </Card.Header>
          <Card.Body className="pt-2">
            <Grid.Row>
              {_.orderBy(witnessForm.inspectionFormFields, ["position"]).map(
                (field) => {
                  return (
                    <InspectionFormField
                      field={field}
                      value={inspectionState[field.name]}
                      signatureValue={
                        field.type == CustomFieldTypes.Signature
                          ? inspectionState[field.name + "_signature"]
                          : null
                      }
                      handleInputChange={handleInspectionInputChange}
                      error={inspectionState[`${field.name}Error`]}
                      key={field.name}
                    />
                  );
                }
              )}
            </Grid.Row>
          </Card.Body>
          <Card.Footer>{getFormButtons()}</Card.Footer>
        </Form>
      </Container>
    );
  } else {
    return (
      <>
        {!_.isEmpty(users) && !_.isEmpty(incidentParticipantTypes) && (
          <IncidentParticipants
            handleSave={handleParticipantsSave}
            handleInputChange={handleInputChange}
            formState={formState}
            witnesses={witnesses}
            setWitnesses={setWitnesses}
            affectedParties={affectedParties}
            setAffectedParties={setAffectedParties}
            users={users}
            incidentParticipantTypes={incidentParticipantTypes}
            isDisabled={() => {
              return false;
            }}
            isLocked={isLocked}
            toggleLock={toggleLock}
            isInitiallyLocked={isInitiallyLocked}
            hasLock={true}
          />
        )}
        {!_.isEmpty(witnessForm) && (
          <Container className="form-container mb-5" maxWidth="xl">
            <Dimmer active={props.isLoading} loader>
              <Form className="card mb-0">
                <Card.Header>
                  <Card.Title>Witness Statements</Card.Title>
                </Card.Header>
                <Card.Body className="pt-2">
                  <Grid.Row>
                    <Grid.Col width={12}>
                      {_.orderBy(existingWitnessStatements, ["name"]).map(
                        (statement) => {
                          return (
                            <Chip
                              clickable
                              onClick={() => {
                                handleStatementClick(statement);
                              }}
                              className="mr-2"
                              variant="outlined"
                              icon={<EditIcon />}
                              label={statement.name}
                            />
                          );
                        }
                      )}
                    </Grid.Col>
                  </Grid.Row>
                </Card.Body>
              </Form>
            </Dimmer>
          </Container>
        )}
      </>
    );
  }
}
